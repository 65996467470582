// Searchbar
.block--search {
	.advance-search{
		display: flex;
	}


	/* .search-option, .multiselect, .project-name-container{
		width: 33%;
	} */
	
	.multiselect{
		border-radius: 3px !important;
		background-color: #fff;
	}
	.multiselect__tags {
		padding: 8px 40px 0 15px;
	}

	.multiselect__content-wrapper{
		z-index: 1;
	}
	.multiselect--disabled {
		opacity: 1;
		cursor: not-allowed;
		pointer-events: auto;
	} 
	.multiselect--disabled .multiselect__select {
		background: transparent;
	}

	.multiselect__input{
	    font-size: 10px !important;
	}

	.search-name{
		width: 100%;
	}

	.parent_location{
		font-weight: bold !important;
	}
	
	.child_location{
		margin-left: 5px;
	}

	.search-option{
	    /* min-width: 200px; */
	    background-color: white;
	    min-height: 40px;
	}
	.autocomplete-suggestion{
		border: 1px solid #e0e0e0;
		background-color: #fff;
		padding: 5px;
		box-shadow: 1px 1px 1px gray;
		max-height: 200px;
		height: 100%;
		overflow-x: auto;
	}

	.advance-option{
		padding: 10px;
		text-decoration: none;
		color: #fff;
	}

	.child_location{
		margin-left: 20px;
		font-weight: 600;
		color: #555;
	}
	.search-option > :first-child{
		color: #929292;
	}
	.search-option > :not(:first-child) {
		color: #333;
		font-weight: 600;
	}
	.advance-search-item {
		position: relative;
	}
	.search-action-area {
		display: flex;
		justify-content: flex-end;
	} 
	.search-btn-name {
		position: absolute; 
		right: 20px;
		padding: 5px 10px;
		font-size: 12px; 
		top: 5px;
	}
	// Autocomplete
	.autocomplete--wrapper {
		max-height: 200px;
	}
	.autocomplete-suggestion {
		border: 0 !important;
		padding: 0 !important; 
		font-size: 14px; 
	}
	.suggestion-option:hover {
		background: #0078D7; 
		color: #fff;
	}
	.suggestion-option {
		padding: 10px;
		margin: 0;
	}
	.results-suggestion { 
		position: absolute;
		left: 0;
		right: 0;
		width: 97%; 
		margin: 0 auto;
		margin-top: 6px;
		z-index: 3;
	} // End autocomplete
	.multiselect__option {
		padding: 10px;
		font-size: 14px;
	}
	.multiselect__input, .multiselect__single {
		font-size: 14px;
	}
	.multiselect__single {
		padding: 0;
	}
	.multiselect__tag { 
		background: #184188;
	}
	.multiselect__tag-icon:after {
		color: #fff;
		&:hover {
			background: #184188;
		}
	}
	.multiselect__option--highlight {
		background: #0078D7;
		&:after {
			background: #0078D7;
		}
	}
	.multiselect__select {
		display: flex;
		align-items: center;
		justify-content: center;
		&:before {
			border: 0;
			width: 15px; 
			height: 15px;
			display: inline-block;
			top: 0;
			background: {
				size: 96%;
				position: center;
				image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAQAAAC1+jfqAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADdcAAA3XAUIom3gAAAAHdElNRQfiCgwFOySRRH4yAAAAdklEQVQoz2NgGHjAGMv9Yy5D1up3mFKRIn+mC0YxLf7K+JxhT6gQFul9DHdm/WZgYGBgCOsPPYeqJFA49GJoO5IAqpJA4dALKNKoSkKFQs8jpBmRlfy3Z3BhYGDYy7BjdSUWBVAlDAw7EdIYIKQ1pH6gQx4TAACLeyfXLHR9MAAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxOC0xMC0xMlQwNTo1OTozNiswMjowMHFgYmAAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTgtMTAtMTJUMDU6NTk6MzYrMDI6MDAAPdrcAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAABJRU5ErkJggg==);
			}
		}
	}
	.front &, .page-categories & {
		.main-search {
			background-color: rgba(0, 0, 0, .5);
			border: 2px solid #fff;
			> div:first-of-type {
				display: flex;
			}
		} 
		.advance-search {
			margin-bottom: 7px; 
		}
		.search-option, .multiselect__tags, input { 
			background-color: rgba(255, 255, 255, 0.7);
			border: 0;
			border-radius: 0; 
			color: #555555;
			&:focus {
				background-color: $white;
			}
		}  
		.multiselect, .multiselect__input, .multiselect__single,  {
			background: transparent;
			&.multiselect--active .multiselect__tags {
				background-color: $white;
			}
		} 
		// Start Normal 
		.form-group__wrapper {  
			padding: 7px 15px; 
			display: flex;
			align-items: center;
			> div {
				display: flex;
				min-width: 101%; 
				> div {
					flex-grow: 1;
				}
			}
		} // End Normal

		// Start Advance
		#advance-search {
			> div {
				> div {
					flex-grow: 1;
				}
			}
			&.collapsing {
				.advance-search-item {
					overflow: hidden;
				}
			}
		}
		.advance-search-item {
			.search-btn-name {
				display: none;
			}
		} // End Advance
		// Search Actions 
		.search-action-area {
			.property-button-search {
				padding: 20px 15px;
				background-color: #184188; 
				color: $white;
				text-align: center; 
				font-weight: 700; 
				font-size: 0; 
				border-radius: 0;
				margin-right: -15px; 
				transition: all ease 300ms;
				&:hover, &:focus { 
					background-color: #071D36;
					transition: all ease 300ms;
				}
				i {
					display: inline-block;
					font-size: 20px;
				}
				@media (max-width: $max-xxs) {
					font-size: 12px;
					margin: 0;
					i {
						font-size: 12px;
					}
				}
			}
			.advance-option {
				font-weight: 700;
				margin-right: 10px;
				display: flex;
				align-items: center;
				font-size: 18px;
				color: #899FC5;
				&.collapsed {
					color: #fff;
				}
				i {
					transform: rotate(180deg);
					transition: all ease .5s;
					margin-left: 10px;
				}
			}
			.collapsed {
				i {
					transform: rotate(0deg);
					transition: all ease .5s;
				}
			}
		}
		.select2  {
			.select2-selection--single {
				border: 0;
				height: 45px;
			}
		} // End search actions
	}
	input {
		&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
			color: #555555;
			opacity: 1;
		}
		&::-moz-placeholder { /* Firefox 19+ */
			color: #555555;
			opacity: 1;
		}
		&:-ms-input-placeholder { /* IE 10+ */
			color: #555555;
			opacity: 1;
		}
		&:-moz-placeholder { /* Firefox 18- */
			color: #555555;
			opacity: 1;
		}
	}
	select {
		color: #555555;
		appearance: none;
		background: { 
			size: 14px;
			repeat: no-repeat;
			position: 96% 50%;
			image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAQAAAC1+jfqAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADdcAAA3XAUIom3gAAAAHdElNRQfiCgwFOySRRH4yAAAAdklEQVQoz2NgGHjAGMv9Yy5D1up3mFKRIn+mC0YxLf7K+JxhT6gQFul9DHdm/WZgYGBgCOsPPYeqJFA49GJoO5IAqpJA4dALKNKoSkKFQs8jpBmRlfy3Z3BhYGDYy7BjdSUWBVAlDAw7EdIYIKQ1pH6gQx4TAACLeyfXLHR9MAAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxOC0xMC0xMlQwNTo1OTozNiswMjowMHFgYmAAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTgtMTAtMTJUMDU6NTk6MzYrMDI6MDAAPdrcAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAABJRU5ErkJggg==);
		}
	}
	input, select, .multiselect__tag {
		margin-bottom: 0px;
		// padding: 9px 30px 9px 10px;
	}
	.block--banner & {
		top: 0; 
		position: relative;
		left: 0;
		right: 0; 
	}
	.multiselect__content {
		overflow-y: auto;
		max-height: 200px;
	}
	.multiselect--active {
		z-index: 33;
	}
	

	// Responsive
	
	@media (max-width: 767px) {
		position: relative;
		top: 0;
		> .container {
			padding: 0;
		}
		.front & .main-search, .page-categories & .main-search {
			background-color: #fff;
		}
		.main-search {
			.front &, .page-categories & {
				border: 0;
				.row-5 {
					margin: 0;
				}
				.field-component {
					background-color: #fff;
					padding: 0;
					border: 1px solid #ccc;
					&.search-action-area {
						border: 0;
					}
					@media (max-width: 374px) {
						width: 100%;
					}
				}
				.form-group__wrapper {
					padding: 0;
					width: 100%;
					> div {
						min-width: 100%;
					}
				}
				.multiselect__select {
					right: 3%;
					top: 10px;
					width: auto;
					height: auto;
					padding: 0;
					background: transparent;
				}
				.search-action-area {
					margin-top: 0;
					transition: all ease 300ms;
					height: 40px;
					display: flex;
					padding: 0;
					> * {
						width: 50%;
						padding: 10px;
					}
					.advance-option {
						font-size: 16px;
						color: #555;
						font-weight: 400;
						@media (max-width: 479px) {
							font-size: 15px;
						}
					}
					button {
						display: flex;
						margin: 0;
						align-items: center;
						justify-content: center
					}
				}
				.field-beds {
					transition: all ease 300ms;
					opacity: 0;
				}
				.panel-collapse {
					padding: 0;
				}
				.property-button-search {
					font-size: 15px;
				}
				&.open {
					.search-action-area { 
						margin-top: 0;
						transition: all ease 300ms;
						@media (max-width: 374px) {
							margin-top: 5px;
						}
					}
					.field-beds {
						transition: all ease 300ms;
						opacity: 1;
					}
				}
			}
		}
	}
	// End Responsive
}