.not-logged-in.page-user {
    font-size: 14px;
    background-color: #f0f2f2;
    color: #4c5052;
    .logo img {
        max-width: 250px;
    }
    .main-wrapper {
        min-height: 90vh;
        display: flex;
        align-items: center;
    }
    .panel {
        padding: 50px;
        background-color: #fff;
        width: 100%;
        max-width: 600px;
        margin: 0 auto;
        border: 0;
        box-shadow: 0 1px 2px rgba(0,0,0,.05);
        border-radius: 0;
    }
    .form-control {
        border-radius: 0;
        -moz-appearance: none;
        -webkit-appearance: none;
        border: 1px solid #ccc;
        background-color: #fff;
        padding: 0 10px;
        height: 45px;
        font-weight: 300;
        width: 100% !important;
        margin-bottom: 0;
        box-shadow: none;
    }
    .form-submit {
        color: #fff;
        background-color: #00397d;
        display: inline-block; 
        font-weight: 700;
        border-radius: 2px;
        border: 0;
        padding: 19px;
        text-transform: uppercase;
        font-weight: 700;
        width: 100%;
        margin: 15px 0;
    }
    .form-label {
        font-size: 11px;
        line-height: 36px;
        letter-spacing: .1em;
    }
    .panel-body {
        padding: 0;
    }
} 