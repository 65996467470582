.footer {
  .not-front & {
    margin-top: 40px;
  }
  color: $white;
  .footer-credits {
    background-color: #313131;
    a {
      color: $white;
    }
    a:hover,
    a:focus {
      color: #ccc;
    }
    .content--left {
      > p {
        position: relative;
        padding: 0 10px 0 0;
        margin: 0 10px 0 0;
        font-size: 12px;
        display: flex;
        align-items: center;
        &::after {
          content: "";
          background-color: #fff;
          height: 70%;
          width: 1px;
          position: absolute;
          right: 0;
          top: 2px;
          bottom: 2px;
        }
        &:last-of-type::after {
          display: none;
        }

        @media screen and (max-width: 767px){
          display: inline-block;
        }
      }
    }
    .content--right {
      justify-content: flex-end;
    }
    > .container {
      display: flex;
      padding: 15px;

      @media screen and (max-width: 767px) {
        padding: 15px;
        display: block;

        > div {
          text-align: center;
          display: block;
        }
      }
      > div {
        padding: 0px;
        flex-grow: 1;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

       
      }
    }
  }
  // Social Media Links
  .m-links {
    display: flex;
    align-items: center;
    // margin: 0 -10px;
    justify-content: flex-end;
    height: 100%;
    > div {
      height: 100%;
      display: inline-flex;
      align-items: center;
      padding: 0 5px;
      a {
        border: 1px solid $white;
        padding: 10px;
        border-radius: 100%;
        display: flex;
        width: 35px;
        height: 35px;
        align-items: center;
        transition: all ease 300ms;
        color: $white;
        justify-content: center;
        font-size: 16px;
        &:hover {
          // margin-top: -10px;
          transition: all ease 300ms;
        }
      }
      &:last-of-type {
        padding: 0px;
      }
    }

    @media screen and (max-width: 767px){
      justify-content: center;
    }
  }
}

.scrollbutton {
  position: fixed;
  z-index: 500;
  right: 20px;
  background-color: $navy-blue-dark;
  color: $white;
  border-radius: 50%;
  border: none;
  height: 40px;
  width: 40px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  opacity: 0.5;
  outline: none !important;
  display: none;
  &.scroll-bottom {
    bottom: 30px;
  }
  &:hover {
    background-color: $blue;
  }
}
