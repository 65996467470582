.admin-wrapper {
	.ql-container {
	    border: 1px dotted transparent;
		.quick-link {
		    position: absolute;
		    top: 0;
		    right: 15px;
		    z-index: 10;
		    display: none;
		}
	    &.relative {
	    	.quick-link { right: 0px; }
	    }
		&:hover {
		    border: 1px dotted $grey;
			.quick-link {
			    display: block;
			}
		} 
		@media(max-width: 768px) {
			.quick-link {
				display: block;
			}
		}
	}
}