$white: #ffffff;


// Flat UI Colors: https://flatuicolors.com/palette/defo
$blue-green: #1abc9c;
$blue-green-dark: #16a085;

$green: #2ecc71;
$green-dark: #27ae60;

$blue: #3498db;
$blue-dark: #2980b9;

$purple: #9b59b6;
$purple-dark: #8e44ad;

$navy-blue: #34495e;
$navy-blue-dark: #2c3e50;

$yellow: #f1c40f;
$yellow-dark: #f39c12;

$orange: #e67e22;
$orange-dark: #d35400;

$red: #e74c3c;
$red-dark: #c0392b;

$silver: #ecf0f1;
$silver-dark: #bdc3c7;

$grey: #95a5a6;
$grey-dark: #7f8c8d;

