.mb20 {
    margin-bottom: 20px;
}
.mb10 {
    margin-bottom: 10px;
}
// Swal
.swal2-popup {
    min-width: 30%;
    min-height: 30%;
    font-size: 15px !important;
}