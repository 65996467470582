// .page-loading {
//     position: fixed;
//     top: 0; 
//     left: 0;
//     height: 100%;
//     width: 100%;
//     z-index: 999;
//     background-color: #fff;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     > p {
//         margin: 15em 0 0;
//     }
// }
// .loader-circle {
//     position: absolute;
//     left: 50%;
//     top: 50%;
//     width: 120px;
//     height: 120px;
//     border-radius: 50%;
//     box-shadow: inset 0 0 0 1px rgba(24, 65, 136, .1);
//     margin-left: -60px;
//     margin-top: -60px;
// }
// .loader-line-mask {
//     position: absolute;
//     left: 50%;
//     top: 50%;
//     width: 60px;
//     height: 120px;
//     margin-left: -60px;
//     margin-top: -60px;
//     overflow: hidden;
//     -webkit-transform-origin: 60px 60px;
//     -moz-transform-origin: 60px 60px;
//     -ms-transform-origin: 60px 60px;
//     -o-transform-origin: 60px 60px;
//     transform-origin: 60px 60px;
//     -webkit-mask-image: -webkit-linear-gradient(top, #184188, rgba(0, 0, 0, 0));
//     -webkit-animation: rotate 1.2s infinite linear;
//     -moz-animation: rotate 1.2s infinite linear;
//     -o-animation: rotate 1.2s infinite linear;
//     animation: rotate 1.2s infinite linear;
// }
// .loader-line-mask .loader-line {
//     width: 120px;
//     height: 120px;
//     border-radius: 50%;
//     box-shadow: inset 0 0 0 3px #184188;
// } 

// .processing-page-load .page-loading::before { 
// 	content: '';
// 	position: fixed;
// 	z-index: 10000;
// 	top: 0;
// 	left: 0;
// 	width: 100%;
// 	height: 100%;
// 	background: #fff; 
// } 

.processing-page-load {
    overflow: hidden;
}
.page-loading {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    align-items: center;
    height: 100%;
    justify-content: center;
    width: 100%;
    flex-flow: column;
    z-index: 999;
    background-color: #fff;
    p {
        margin: 15px 0 0;
    }
    &::before {
        content: '';
        // position: fixed;
        // z-index: 10000;
        // top: calc(50% - 30px);
        // left: calc(50% - 30px); 
        width: 60px;
        height: 60px;
        border-radius: 50%;
        border: 7px solid;
        animation: loaderAnim 0.8s linear infinite forwards;
    }
    .filinvest &::before, .aspire &::before {
        border: 7px solid #446eb7;
        border-left-color: #184188; 
    }  
    .prestige &::before{
        border: 7px solid #deb589;
        border-left-color: #9E662B;
    }
    .futura &::before {
        border: 7px solid #e85d5b;
        border-left-color: #e02826;
    }
}
@keyframes loaderAnim {
	to {
		transform: rotate(360deg);
	}
} 