html {
  position: relative;
  min-height: 100%;
}
a {
  &,
  &:hover,
  &:focus {
    text-decoration: none;
  }
}
.scrollTop {
  display: inline-block;
  background-color: #ccc;
  border: 1px solid #f5f5f5;
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 4px;
  position: fixed;
  bottom: 10px;
  left: 10px;
  transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
  &:hover,
  &:active {
    cursor: pointer;
  }
  &.show {
    opacity: 1;
    visibility: visible;
  }
}
.pre-line {
  white-space: pre-line;
}
.relative,
.ps-relative {
  position: relative;
}

.d-flex {
  display: flex;
}
.ai-c {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.jc-c {
  justify-content: center;
  -ms-flex-pack: center;
}
.jc-sb {
  justify-content: space-between;
}
.align-item--advertisement {
  align-items: baseline;
}
// For image sizes
@mixin img-size-mixin($width, $height) {
  .img-size-#{$width}-#{$height} {
    width: auto;
    height: auto;
    max-height: #{$height}px;
    max-width: #{$width}px;
    margin: 0 auto;
    &.img-size-full-width {
      width: 100%;
    }
    &.img-size-fixed {
      width: 100%;
      min-height: #{$height}px;
      min-width: #{$width}px;
    }
  }
}
// 1 - 90
@for $w from 1 through 9 {
  $width: $w * 10;
  @for $h from 1 through 9 {
    $height: $h * 10;
    @include img-size-mixin($width, $height);
  }
}
// 100 - 1000
@for $w from 1 through 10 {
  $width: $w * 100;
  @for $h from 1 through 10 {
    $height: $h * 100;
    @include img-size-mixin($width, $height);
  }
}
// 1000 - 1024
@for $w from 1 through 10 {
  $width: ($w * 100) + 50;
  @if $width > 1024 {
    $width: 1024;
  }
  @for $h from 1 through 10 {
    $height: ($h * 100);
    @include img-size-mixin($width, $height);
    @if $height > 1024 {
      $height: 1024;
    }
    $height: ($h * 100) + 50;
    @include img-size-mixin($width, $height);
  }
}

// Lazyload

.has-blur,
.v-lazy-image {
  filter: blur(3px);
  //   transition: filter 0.7s;
}
.has-blur.lazyloaded,
.v-lazy-image-loaded {
  filter: blur(0);
  //   transition: filter 0.7s;
}

body {
  font-size: 16px;
}

* a {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

.o-hidden {
  overflow: hidden;
}

.ps-relative {
  position: relative;
}

.uppercase {
  text-transform: uppercase;
}
.italic {
  font-style: italic;
}

.inline {
  display: inline;
}
.inline-block {
  display: inline-block;
}

.m0 {
  margin: 0 !important;
}
.m0-auto {
  margin: 0 auto;
}

.ml-30 {
  margin-left: -30px;
}

.mt20 {
  margin-top: 20px;
}

.p0 {
  padding: 0 !important;
}
.p10 {
  padding: 10px;
}
.p20 {
  padding: 20px;
}
.pt10 {
  padding-top: 10px;
}
.pt15 {
  padding-top: 15px;
}
.pt20 {
  padding-top: 20px;
}
.pt30 {
  padding-top: 30px;
}
.pt40 {
  padding-top: 40px;
}

.pt60 {
  padding-top: 60px;
}
.pb25 {
  padding-bottom: 25px;
}
.pb30 {
  padding-bottom: 30px;
}
.pb50 {
  padding-bottom: 50px;
}

.ptb10 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.ptb20 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.ptb60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.plr20 {
  padding-left: 20px;
  padding-right: 20px;
}

.fs-18 {
  font-size: 18px;
}
.fs-14 {
  font-size: 14px;
}

.fw700 {
  font-weight: 700;
}

.tc-white {
  color: #fff;
}
.rounded {
  border-radius: 100%;
}
.bg-gray {
  background-color: #eee;
}
.fs80 {
  font-size: 80px;
}

.social-sharing {
  .block__label {
    margin-right: 10px;
  }
  .social-btn {
    display: flex !important;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    margin: 0 2.5px;
    transition: all ease 300ms;
    padding: 3px 10px;
    border-radius: 2px;
    font-weight: 600;
    i {
      font-size: 14px;
      margin-right: 5px;
    }
    &:focus,
    &:hover {
      color: $white;
      margin-top: -5px;
      transition: all ease 300ms;
    }
    &.button-facebook {
      background-color: #3b5999;
    }
    &.button-twitter {
      background-color: #3aacd1;
    }
    &.button-googleplus {
      background-color: #c7594c;
    }

    &:first-child {
      margin: 0 2.5px 0 0;
    }
  }
}

.lh-30 {
  line-height: 1.5;
}

.article-loader {
  display: inline-flex;
  align-items: center;
  margin-right: 10px;
  vertical-align: middle;
  &::before {
    content: "";
    // position: fixed;
    // z-index: 10000;
    // top: calc(50% - 30px);
    // left: calc(50% - 30px);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    animation: loaderAnim 0.8s linear infinite forwards;
    border: 5px solid #446eb7;
    border-left-color: #184188;
  }
}
@keyframes loaderAnim {
  to {
    transform: rotate(360deg);
  }
}

lesshat-selector {
  -lh-property: 0;
}
@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes rotate {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-o-keyframes rotate {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(360deg);
  }
}
@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
[not-existing] {
  zoom: 1;
}
lesshat-selector {
  -lh-property: 0;
}
@-webkit-keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.25;
  }
}
@-moz-keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.25;
  }
}
@-o-keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.25;
  }
}
@keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.25;
  }
}
[not-existing] {
  zoom: 1;
}
lesshat-selector {
  -lh-property: 0;
}
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
[not-existing] {
  zoom: 1;
}
.checked {
  // background-image: url('/img/icons/checked.svg');
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIIAAACCCAMAAAC93eDPAAAAgVBMVEUAAABqwlhmxFBlwldew1BlwVJpwlhkw1dqwllpwlhpwllqwlhpwlhpwlhpwlhexEhowlhowldowVdpwlhpwlhpwlhov1ZpwVhpwllpwldpwlhqwlhowlhpwlhpwlhpwlhpwVhqwlhpwlhpwVhowldpwlhowFZmv1Rqwlhpwldqwll7MLX+AAAAKnRSTlMA+wwbCBHgFvT47OTO1TYFL1ZP+ei0KUK6ZNuske/SYMjAmYptW0siooL9w1fTAAAERUlEQVR42tXb15LTMBgF4F+24riluKY7ZdP2vP8DwgCDZpbEsspv4LuFizPHshTJWrLzWB0/n+s8SmMh4jTK18/PzepBI3kU+1OCl5LTvuDOEaw+ImhkH6uAmAT3HQbalRwp2o8KBqqPJXm1uOcw1pWSfJFFBiv1TXoKUMNaWngIsdzCSTQnN9MGztZTl1FYVPBAHBdkaXKCJ/mErKwqeFOtbB7CE17tzB/GDJ5tTYfBAd7FE6PJIAGDxGCKuAvwKGmgmwATsRmYAIw2DE+B4VksBXhpx+QkATMRUr8D2MWyd1aeYQSzvrn6glE8e9ZGjOSuG4r8qvDNQDhhNPnr4VBAh3uWnFbQYJ8dGoxq/WJixshW9IXcYmSR1I1FfscvJdQYXRqMU8LsNLCGBVcJs2mQ451aknIHj9mEqCdDQUoOFtspfRfM8EanErRgsZ3QD0Gu/xH3AQ7RjwS9Pex//48KAEsCXYYq4ByM2YRICbb9A3IH/7KAaECG5te/QmGY+frn30D9YuROQNMEr5RM70M8LIF6JyLvCeTgBMjou8cYHRzwTqsWSdYO4v7lcs/eQZjivQsRnRg70CdATkQJ99tYo09C9GBOENbo13qdmLIXCTJolHT8ywlwpU+G1VkliKC1p+dfToAdreHH1jIBzvpfrk3DmgCddpFay6AZsl+wTYCMUk0Hkkg22gTWHQAHijUd0HdBY95BhoFiEpoOfpBNfwcOCSBIaDpQGQw6mNYwiBD3d6AymHRgkABxz3A8SyJFrod3kMLAgaL+p6As1j0d2CdA9nZq6iR9Ic89Hdh/3OveTtBiTl8tzkM6MEyAMz1hkOH0x/mB+zn2jj5hkIFOmnVhmsDUnjYwypD3rM52Z/lXWsEyQ+TUgVLSA3YZMucO1HYqscqQBX46QKXdyojlywxp4N6B2srsYZ4hde1AuQzY1oqWFP+fWDdqc2/Ug5cO1OaeMmgkLd+lj3rgQU+y1HRgnQCXocddcdubIHW9SxBAL+7pIUxh79cEt3PKMK1hr6GfSjhkCF0S4KaOwa0zhBkciIn6GGCTwT0BLrrPpPr3Ioy83WvpYNGDe4IZKSWGSVqfCXCz+VKaLD0mSKXVjS7RqgR+7zDIdHCGpUrg95CygFkPYeb9IoeMhmeYe0kQBRZXKFSGsPZ4hUJZG2RI4exMf5oKMNNftjtiRFd6ZZFjNN2CXppUGEkV/vVrdiW9tcModvTeYosRRAvqE4NdLP/567c0B7M5aZUCnAoaYANGVyKXDPwJlBJMChpsLsBAzMlAGMO7OCQjcgbPIvkf/tES0b2CN1VJVsIcnnQhWVpsBDwQ1wXZC9dwdg7JzTyCk2hFzuQxhbV0E5APwbHmDqAniw7GZjdJXs33FQyIy5z8C4oGAzW3CTEJyn0GjfpSTohXe7zkCV6q8sumpZG05XW/O3fZIRYiPmTdebe/li1Z+QZrS40KoBDpLQAAAABJRU5ErkJggg==);
  height: 130px;
  width: 130px;
  display: inline-block;
  max-width: 100%;
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
}

.has-sticky {
  &,
  .to-load,
  .main-wrapper {
    overflow: inherit;
  }
  .sticky {
    top: 50px;
    position: sticky;
    margin-bottom: 30px;
    .logged-in & {
      top: 70px;
    }
  }
  &.page-careers .sticky {
    top: 15px;
  }
}
.w-100 {
  width: 100%;
}
.property-button-search[disabled] {
  pointer-events: none;
}
.multiselect__placeholder {
  color: #555555 !important;
}

.parent_location {
  color: black !important;
  text-transform: uppercase;

}
.align-self-center {
  align-self: center;
}
.bgc-light-grey {
  background-color: #ebe9e9;
}
.cookies-wrapper {
  position: fixed;
  bottom: 0px;
  left: 0;
  right: 0;
  border-top: 3px solid #184188;
  background-color: rgba(0, 0, 0, .4);
  color: #fff;
  padding: 20px;
  z-index: 99;

  @media screen and (max-width: 767px){
    padding: 20px 5px;
    .wrapper {
      flex-direction: column;
      justify-content: end;
      align-items: end;
    }

    .cancel {
      position: absolute;
      right: 40px;
      top: 20px;
      width: 30px;
      background-size: 100%;
      height: 30px;
    }

    .flex-1 {
      flex: none;
      padding: 0;
    }

    .cancel-icon {
      position: absolute;
      right: 0;
      top: 5px;
    }
  }

  &.append {
    // margin-top: 50px;
    position: relative;
    background-color: #313131;

    // .not-front & {
    //   margin-top: 50px;
    // }
  }
}
.cancel-icon {
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAABHNCSVQICAgIfAhkiAAAA8dJREFUWIW1mG2IVUUYx/9z3V5Ysm1N3VZFDd/YFKEopcikCKSkJcxk0xCMVojUEKQU+5CgImxEUBH4IT8IUdiLvRCEFBIqIaZWhopu29vGsqsGqSit7a8P5znc8Xbn3pnr3YHzYZ6X//M7c+bMzDlOCQ0oSJojqV3SPEm32tUsqV/SKUmnJX0v6WPnXG+KfgrIjcArwABp7SjwMnBLPWE6gN+twL/Ad0AXsBCYDbQCDUATMBW4H9gI/OiB/Q1sBUZd66js9kQ/AiYnaswCdnka54D7aoEZBxw2kR5gXrLI1XpzbWQBBoEVKcmtQK8l7wearwXG020AtgBDpv1iTNIN3si8DzTUA6akxsPAZQN7pFrwewZzGLiu3jBenaVW5zwwIxTUbkH9QOtwwXj1XrN6B8o5RwAnLeCpgEBbLaMGTAZuCtT8zWo+XupcaY4TAdEHzX8QuDkBZrnNlS8r+LHBGOE7jppjUSCx0ZvsR2LePKDTYAaBhYGYAnDKdBfkxlYzXLyK8v/JTR7UsUpQwCqLuwK0VwHfYLFv54bVZtgVcddNwA8e1OgqMIsjNNss/s/c8KEZnq2WbPGjPKgTQIvnW2f2oRgYLy+f3NMFfGud6O3BoI5bXjfQQnYayGGWxWqZ3jeWO1/Ar9aZligyxpuQf3kwz6TomNYHlt9RkJQvgv0pIs65AWWHtAFJ+Xlng3PunVQgr/ZtBUkXrNNYg9DTksZ4/RXlJnpEyxfOiwVJfdZJ2i6AdZK6rLtNUq+kGZL2+RM9suW1+wR8bc/vsQSYTRTbKrNN8uZjNzA+Qe8ny7tHwJvWeTUyucuD6SzxjTcYDK4qFNnaNkS2bo0UsMAEeiKS3/Deps5ATEsJ1KQqmsss9qvccD3ZtpENWThxuwezvEqRFrJFE+CPSlDAuxa3xjfuNOMngaSHzH8F6KgE4+WMJtteAD4PxEwA/jHdCb7jdjMCzAnc8R5gSQyMl9dMtjU9F/DvsJpvlXPm8+MQw3h89erdRfadd4Fy32tkW8FZg6pltU2BmQj0Wa2XKgU+4D26F4YJZqz3Fn4Rk/A8xba5zjCzKB41TlLmrB1K3OhB7Qaa6gDzBMXl5TgwMVXgSeCSCZwhO1kmfziSnQj3eje4J3pkyojdTfEcDXDaRi/40wFw9mjWAJ/ZmwTZerOF7B9TsLlIsEclbZZ0p2c+IukXZeehM5LGSbpDUpukkSUSn0pa65z7OaZedANmAuuBAxR/GoRaN/A6cG9KjagRCsA1SpoiaZpdg5LOSjonqcc5d6wW3f8AuvCem2CLvcgAAAAASUVORK5CYII=);
}

.show-cookies {
  .c-title {
    @media screen and (max-width: 767px){
      width: 100%;
    }
  }
  .zsiq_theme1.zsiq_floatmain {
    bottom: 17vh;

    @media screen and (min-width: 576px) and (max-width: 767px){
      bottom: 22vh;
    }
    @media screen and  (max-width: 575px){
      bottom: 36vh;
    }
  }

  @media screen and (max-width: 767px){
    .block__rm {
      text-align: center;
    }
  }
}

.popup-ad {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  display: -ms-flexbox; /* will work on IE10 */
  display: flex;
  justify-content: center;
  align-items: center;
  background: none 0 0 repeat scroll rgba(0, 0, 0, 0.3);
  /*background: none 0% 0% repeat scroll rgba(27, 27, 27, 0.8);*/

  .popup-wrapper {
    position: relative;
   
    img {
      display: inline-block
    }
  }

  .popup-content {
    padding: 55px;
  }

  .cancel-icon {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 25px;
    background-size: contain;
    height: 25px;

    // @media screen and (max-width: 575px){
    //   right: -5px;
    //   top: -50px;
    // }
  }

  .block-details {
    padding: 15px;
  }

  .block__title {
    text-align: left;
    padding: 15px;
  }

  .block__rm {
    padding: 15px 0;
    
    a {
    padding: 15px;
    }
  }

  .processing-page-load & {
    display: none;
  }
}
.fs28 {
  font-size: 28px;
}
.fs50 {
  font-size: 50px;
}
.fs55 {
  font-size: 55px;
}
.ml10 {
  margin-left: 10px;
}

.ml5 {
  margin-left: 5px;
}

.px-15{
  padding: 0px 15px;
}

.fav-wrapper {
  position: absolute;
  top: 13px;
  right: 10px;
  color: $white;
  cursor: pointer;
  z-index: 11;
  transition: transform .2s ease;
  filter: drop-shadow(1px 0px 1px #000);

  i {
    // padding: 10px;
    // font-size: 20px;
  }

  &:hover {
    transform: scale(1.3);
  }

  .favorite-button {
    font-size: 20px;

    &.fav-icon-inactive {
      &::after {
        content: '\f08a';
      }
    }

    &.fav-icon-active {
     &::after {
      content: '\f004';
     }
    }
  }


}
.favorite-remove {
  position: absolute;
  right: 10px;
  z-index: 10;
  color: #ffffff;
  background-color: #ff7675;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  top: 10px;
  cursor: pointer;
}
.xs-show {
  display: none;
  @media screen and (max-width: 767px){
    display: inline-block;
    padding-left: 20px;
  }
}
.advertisement {
  .h-slider {
    @media screen and (max-width: 767px) and (min-width: 576px){
      .item {
        flex: 0 25%;
        width: 25% !important;
        display: inline-block;
        max-width: 25% !important;
      }
    }
    @media screen and (max-width: 575px) {
      .item {
        flex: 0 50%;
        width: 50% !important;
        display: inline-block;
        max-width: 50% !important;
        min-height: 150px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .ads-header {
      padding: 0 !important;
      
      >.container {
          padding: 15px;
      }

      .tagline {
          font-size: 20px !important;
          margin-bottom: 10px !important;
          padding-left: 0;
          padding-right: 0;
          p {
            margin: 0;
          }
      }

      .secondary_tagline {
          font-size: 14px !important;
          padding-left: 0;
          padding-right: 0;
      }
    }
  }
}

// $fav-icon : (
//   // fave_inactive
//   inactive: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyOC4xIiBoZWlnaHQ9IjI1IiB2aWV3Qm94PSIwIDAgMjguMSAyNSI+DQogIDxwYXRoIGlkPSJfMDAxLWhlYXJ0IiBkYXRhLW5hbWU9IjAwMS1oZWFydCIgZD0iTTE0LjA1LDI1YTEuNjQ4LDEuNjQ4LDAsMCwxLTEuMDg2LS40MDhjLTEuMTM1LS45OTMtMi4yMy0xLjkyNS0zLjItMi43NDhsMCwwYTU5LjQyMyw1OS40MjMsMCwwLDEtNi45NzctNi41NDhBMTAuNDU5LDEwLjQ1OSwwLDAsMSwwLDguNDQ1YTguNzgzLDguNzgzLDAsMCwxLDIuMjI5LTZBNy41NTYsNy41NTYsMCwwLDEsNy44NSwwYTcuMDY4LDcuMDY4LDAsMCwxLDQuNDE1LDEuNTI0QTkuMDMzLDkuMDMzLDAsMCwxLDE0LjA1LDMuMzg4YTkuMDM0LDkuMDM0LDAsMCwxLDEuNzg1LTEuODY0QTcuMDY4LDcuMDY4LDAsMCwxLDIwLjI1LDBhNy41NTcsNy41NTcsMCwwLDEsNS42MjEsMi40NDcsOC43ODMsOC43ODMsMCwwLDEsMi4yMjksNiwxMC40NTgsMTAuNDU4LDAsMCwxLTIuNzg3LDYuODQ2LDU5LjQxNyw1OS40MTcsMCwwLDEtNi45NzYsNi41NDhjLS45NjcuODI0LTIuMDYzLDEuNzU4LTMuMiwyLjc1M0ExLjY0OSwxLjY0OSwwLDAsMSwxNC4wNSwyNVpNNy44NSwxLjY0NkE1LjkyOCw1LjkyOCwwLDAsMCwzLjQzOSwzLjU2Miw3LjE0NCw3LjE0NCwwLDAsMCwxLjY0Niw4LjQ0NWE4LjgsOC44LDAsMCwwLDIuNDA4LDUuOCw1OC40NzcsNTguNDc3LDAsMCwwLDYuNzc3LDYuMzQ2bC4wMDUsMGMuOTY5LjgyNiwyLjA2OCwxLjc2MiwzLjIxMiwyLjc2MiwxLjE1LTEsMi4yNTEtMS45NCwzLjIyMi0yLjc2N2E1OC40OTEsNTguNDkxLDAsMCwwLDYuNzc2LTYuMzQ1LDguOCw4LjgsMCwwLDAsMi40MDgtNS44LDcuMTQ0LDcuMTQ0LDAsMCwwLTEuNzkzLTQuODgzQTUuOTI4LDUuOTI4LDAsMCwwLDIwLjI1LDEuNjQ2YTUuNDU5LDUuNDU5LDAsMCwwLTMuNDA4LDEuMTgsNy45ODcsNy45ODcsMCwwLDAtMS45LDIuMiwxLjAzOSwxLjAzOSwwLDAsMS0xLjc4NSwwLDcuOTgsNy45OCwwLDAsMC0xLjktMi4yQTUuNDU5LDUuNDU5LDAsMCwwLDcuODUsMS42NDZabTAsMCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCAwKSIgZmlsbD0iIzVkNWQ1ZCIvPg0KPC9zdmc+DQo=',
// // fave_active
//   active: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNy4xNzQiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyNy4xNzQgMjUiPg0KICA8ZyBpZD0iXzAwMi1mYXZvcml0ZS1oZWFydC1idXR0b24iIGRhdGEtbmFtZT0iMDAyLWZhdm9yaXRlLWhlYXJ0LWJ1dHRvbiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCAtMjAuNCkiPg0KICAgIDxnIGlkPSJmYXZvcml0ZSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCAyMC40KSI+DQogICAgICA8cGF0aCBpZD0iUGF0aF8xMDYiIGRhdGEtbmFtZT0iUGF0aCAxMDYiIGQ9Ik0xMy41ODcsNDUuNGwtMS45LTEuOUM0LjYyLDM3LjI0OCwwLDMzLjAzNiwwLDI3Ljg3M0E3LjM2Nyw3LjM2NywwLDAsMSw3LjQ3MywyMC40YTguMTY1LDguMTY1LDAsMCwxLDYuMTE0LDIuODUzQTguMTY1LDguMTY1LDAsMCwxLDE5LjcsMjAuNGE3LjM2Nyw3LjM2NywwLDAsMSw3LjQ3Myw3LjQ3M2MwLDUuMTYzLTQuNjIsOS4zNzUtMTEuNjg1LDE1LjYyNVoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgLTIwLjQpIiBmaWxsPSIjNGQ0ZDRkIi8+DQogICAgPC9nPg0KICA8L2c+DQo8L3N2Zz4NCg==',
// );

// i[class*="fav-icon-"] {
//   mask-size: contain;
//   mask-position: 50% 50%;
//   mask-repeat: no-repeat;
//   background: currentColor;
//   width: 16px;
//   height: 16px;
//   display: inline-block;
 
// }
// $icons-uri: $fav-icon;
// @each $icon, $data in $icons_uri {
//   i[class*="fav-icon-#{$icon}"] {
//     mask-image: url(#{$data});
//   }
// }
.zsiq_custommain.siq_bR {
  right: 5px;
  @media screen and (max-width: 767px){
   transform: scale(.9);
   right: 0px;
  }
}
.sns-wrapper {
    position: fixed;
    background-color: rgba(24, 65, 136, 0.74);
    // bottom: 15px;
    // right: 0;
    padding: 10px;
    border-bottom-left-radius: 25px;
    border-top-left-radius: 25px;
    z-index: 999;
    right: -5px;
    bottom: 90px;

    @media screen and (max-width: 767px){
      padding: 4px 3px;
    }

  .m-links {
    display: flex;
    align-items: center;
    // margin: 0 -10px;
    justify-content: flex-end;
    height: 100%;

    // @media screen and (max-width: 767px){
      flex-direction: column;
    // }
    > div {
      height: 100%;
      display: inline-flex;
      align-items: center;
      padding: 5px;
      // @media screen and (min-width: 768px){
      //   padding: 0 5px;
      // }
      a {
        border: 1px solid $white;
        padding: 10px;
        border-radius: 100%;
        display: flex;
        width: 35px;
        height: 35px;
        align-items: center;
        transition: all ease 300ms;
        color: $white;
        justify-content: center;
        font-size: 16px;
        &:hover {
          // margin-top: -10px;
          transition: all ease 300ms;
        }

        @media screen and (max-width: 767px){
          width: 32px;
          height: 32px;
          font-size: 17px;
          padding: 7px;
        }
      }
      // &:last-of-type {
      //   padding: 0px;
      // }
    }

    // @media screen and (max-width: 767px){
    //   justify-content: center;
    // }
  }
}

select::-ms-expand {
  display: none;
}

.ie-browser {
  .popup-ad {
    .container {
      width: 65%;
      margin-left: auto;
      margin-right: auto;
      margin: 0 auto;
      text-align: center;
      max-width: 65%;
      margin-left: 0;
    }
    .col-md-7 {
      margin: 0 auto;
    max-width: 100%;
    width: 100% !important;
    }
  }
}

.slick-arrow.slick-disabled {
  pointer-events: none;
}