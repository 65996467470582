// Image Hover include this to .item--wrapper or .item
@mixin imageHover {
    .item__image { 
        img {
        transform: scale(1);
        transition: all ease 1s;
        }
        
    }
    &:hover .item__image {
        img {
            transform: scale(1.1);
            transition: all ease 1s;
        }
    }
}

// Full XXS
@mixin smFull {
    @media (max-width: $max-sm) {
        width: 100%;
    }
}
@mixin xxsFull { 
    @media (max-width: $max-xxs) {
        width: 100%;
    }
}