//-- Paragraph Articles
.paragraph-item-article {
  &.paragraph-type-content {
    a {
      color: #184188;
    }
    p {
      line-height: 28px;
      margin-bottom: 20px;
    }
  }
  &.paragraph-type-image-content {
    .with-caption {
    }

    @media (max-width: $max-sm) {
      margin: 0 -15px;
      figcaption {
        padding: 0 15px;
      }
    }
  }
  &.paragraph-type-embed {
    iframe {
      width: 100%;
    }

    [class^="twitter"] {
      margin: 0 auto;
      padding-bottom: 10px;
    }
  }
}
// -- Paragraph Articles
