@media only screen and (min-width : 320px) {
    .footer-sprite {
        background-image: url('/img/icons/spritesheet.png');
        background-repeat: no-repeat;
        display: block;
    }
    
    .sprite-Facebook {
        width: 35px;
        height: 35px;
        background-position: -5px -5px;
    }
    
    .sprite-LinkedIn {
        width: 35px;
        height: 35px;
        background-position: -50px -5px;
    }
    
    .sprite-Twitter {
        width: 35px;
        height: 35px;
        background-position: -95px -5px;
    }
    
    .sprite-YouTube-2 {
        width: 35px;
        height: 35px;
        background-position: -140px -5px;
    }
}