
.page-search, .page-categories {
    .item--header {
        display: flex;
        top: 0;
        position: absolute; 
        width: 100%;
        z-index: 2;
        > * {
            position: relative;
            z-index: 1;
            flex-grow: 1;
        }
    }
    
    // Adding this line temporarily
    .slick-slider > .item {
        display: none;
    }
}
.page-categories {
    .sold-project {
        .sold__tag {
            margin: 0;
        }
    }
}
.sold-project {
    .item__image, .brand-image {
        //opacity: .5;
    }
    .sold__tag {
        background: rgba(0, 0, 0, 0.6);
        color: #fff;
        padding: 12px 20px;
        font-size: 18px;
        // text-align: right;
        // justify-content: flex-end;
        // margin-left: 5px;
				line-height: 30px;
				
				position: absolute;
				left: 0;
				right: 0;
				top: 45%;
				margin: 0;
				z-index: 11;
				text-align: center;
				font-weight: 600;
				@media screen and (max-width: 1199px) {
					padding: 10px;
				}
    }
}

.page-search {
    // Start Search Block
	.block--search {
		.container {
			padding: 20px 15px;
			width: 100%;
			border-bottom: 3px solid $blue;
			position: relative;
			@media (max-width: $max-sm) {
				padding: 0;
			}
		}
		.main-search {
			max-width: 1170px;
			width: 100%;
			margin: 0 auto;
			.multiselect__tags {
				border-radius: 0;
				border: 1px solid #D5DEEC;
			}
			.form-group__wrapper {
				width: 100%;
				@media (max-width: $max-sm) {
					padding: 0;
				}
				> div {
					display: flex;
					@media (max-width: $max-sm) {
						margin: 0;
					}
				}
				.row-5 > div {
					@media (min-width: $min-sm) {
						flex-grow: 1;
						width: 33.33%;
					}
					@media (max-width: $max-sm) {
						padding: 0;
					}
					&.field-component-select {
						@include smFull;
					}
				}
			} 
			#advance-search {
				width: 100%;
				@media (max-width: $max-sm) {
					padding: 0;
				}
			}
			.advance-search {
				margin-top: 20px;
				padding: 0 15px;
				margin: 20px -20px 0;
				@media (max-width: $max-sm) {
					padding: 0;
					margin: 0;
				}
				> div {
					width: 33.33%;
					@media (max-width: $max-sm) {
						padding: 0;
					}
					@include xxsFull;
				}
			}
				
		}  
		.row {
			margin-left: auto;
			margin-right: auto;
			float: none;
			max-width: 1200px; 
			&:last-of-type {
				margin-bottom: 0;
			}
		}
		select, .form-control { 
			border: 1px solid #D5DEEC;
			padding: 10px;
			border-radius: 0; 
		}
		.search-action-area {
			position: absolute;
			display: flex;
			margin: 0 auto;
			left: 0;
			right: 0;
			bottom: -51px;
			align-items: center;
			justify-content: center;
			overflow: hidden;
			height: 50px;
			width: 100%;
			@media (max-width: $max-sm) {
				justify-content: flex-end;
			}
			a { 
				font-size: 0; 
				border-radius: 100%;
				top: -18px;
				display: flex;
				position: relative;
				height: 75px;
				justify-content: center;
				align-items: center;
				width: 80px;
				background-color: $blue;
				&.advance-option {
					i {
						transition: all ease 500ms;
						transform: rotate(180deg);
					}
				}
				&.collapsed {
					i {
						bottom: -10px;
						transition: all ease 500ms;
						transform: rotate(0);
					}
				}
			}
			i {
				font-size: 30px; 
				position: relative;
				bottom: -15px;
				&::before {
					content: "\f078";
				}
			}

		}
	} // End Search Block
}


// Page Articles
// News 
.page-articles {
	// Start Main Content
	.main-content {
		@media (max-width: $max-md) {
			padding: 0;
		}
		.item {
			@include imageHover;
			&:hover {
				.item--wrapper { 
					background-color: #EBFCFF;
					transition: all ease 300ms;
				}
				.item__rm {
					a {
						transition: all ease 300ms;
						background-color: #C4DFEE;
					}
				}
			}
		}
		.item--wrapper {
			height: 100%; 
			display: flex;
			flex-flow: column;
			padding-bottom: 50px;
			transition: all ease 300ms;
			.item--description {
				flex-grow: 1;
			}
			
			.item__rm {
				position: absolute;
				bottom: 0;
				width: 100%;
				left: 0;
				right: 0;
				a {
					display: block;
					width: 100%;
					background-color: #D9D9D9;
					padding: 15px;
					text-align: center;
					transition: all ease 300ms;
				}
			}
		} 
		.item__image, .progressive-image {
			height: 250px;
			max-width: 100% !important;
			img { 
				object-fit: cover; 
				width: 100%;
				height: 100%;
			}
		}
	} // End Main Content
	
}

// Start Sidebar filters

.sidebar-secondary {
	.form-control {
		width: 100%;
	}
	.fb-page {
		padding: 0 0 10px;
		background-color: #F4F5F6;
	}
	.filter {
		margin-bottom: 35px;
		border: 1px solid #ccc;
		input {
			padding: 15px 30px 15px 15px;
		} 
		i.btn {
			position: absolute;
			right: 0;
			top: 0px;
		}
	} 
	.form-label {
		margin: 0 0 25px;
		font-weight: 400;
		text-transform: capitalize;
	}
	.dropdown-toggle {
		border-radius: 0;
		background-color: #fff; 
		border: 1px solid #ccc;
		width: 100%;
		text-align: left;
		padding: 10px 5px;
		color: $blue;
	}
} // End Sidebar
// End Page Articles