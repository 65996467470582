/* .banner-wrapper {
	max-width: 1920px;
	width: 100%;
	margin: 0 auto;
}
.banner-wrapper {
	min-height: 890px;
	max-height: 890px;
	overflow: hidden;
	position: relative;

	img { 
		width: 100%;
	}

	// remove after applying image style
.carousel-inner {
	height: 890px;
}

.carousel-caption {
	top: 20%; 
	bottom: initial;
}
}
.search-bar {
	padding: 20px;
	background-color: rgba(28, 28, 28, .75);
}
.search__block {
	position: absolute;
	bottom: 20%;
	z-index: 2;
	left: 30%;
    right: 30%;
}

.search-icon {
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAAAjVBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////8DizOFAAAALnRSTlMABKL65bymimQG9+rRxrR1QiQUCvTu4dbMro+BfGxSRzovJRsOtZ6VgHBdTjEpawbYBQAAALlJREFUGNNlj1cOwjAQBe10UoHQO0noMPc/HnYSKxbMz1s970hr0XK8prHnF7UYOKV0uJuR6SIXknAbLIF02qswi6SeJhdYtZP0iM9GCmDfyvAQhtLD07kmUYZhD28VCwIxUEGkwiW0Sgk7FXNuVjmFQsWKzCrH8FIRwmQoHdDnfyCzF3Pz6PRfPiVGK5ew2I0bcXZcIO+ubnxs/F47ZGjmmyrXbSk66kNxf6oVuQYc8YMMYCb+COPtF4vdFfdF5J6rAAAAAElFTkSuQmCC');
	height: 40px;
	width: 40px;
	background-repeat: no-repeat;
    background-position: center;
}

.property-button-search{
	background-color: transparent;
	border: 1px solid #7b6b5e;
	padding: 8px 10px;
}
 */