// Start Article 
.node-type-article {
    .nav__slider.less-slick .slick-track {
        transform: none !important;
    }
    &, .to-load, .main-wrapper {
		overflow: inherit;
    }
    .paragraph-type-image-content {
       figcaption {
           margin-top: 10px;
       }
    }
	.sticky {
		top: 50px;
		position: sticky;
		margin-bottom: 30px;
		.logged-in & {
			top: 70px;
		}
    }
    .sidebar-secondary { 
        input {
			padding: 15px 30px 15px 15px;
		} 
        @media (min-width: $min-sm) {
            padding: 0;
        }
        
    }
    .article--header {

    }
    .fb-page {
        width: 100%;
        display: block;
    }
    @media (max-width: $max-sm){
        .page-header {
            font-size: 25px;
            text-align: left;
        }
        .post-date {
            display: block;
            text-align: left;
        }
        .divider {
            display: none;
        }
    }
} // End Article

.node-type-page {
    .page-header {
        
    }
    .article__body {
        // @media (min-width: $min-lg) {
        //     width: 750px;
        // }
        ul, ol {
            @media (max-width: $max-sm) {
                padding-left: 20px;
            }
            @media (max-width: $max-xxs) {
                padding-left: 10px;
            }
        }
        ul {
            list-style: none;
            li {
                &::before {
                    content: '';
                    border-radius: 100%;
                    width: 8px;
                    height: 8px;
                    display: inline-block;
                    vertical-align: middle;
                    background: #ccc;
                    margin-right: 10px;
                }
            }
        }
        table {
            border: 0;
            width: 100% !important;

            p {
                margin-bottom: 0px;
            }
        }
        th, td {
            padding: 15px;
            border: 1px solid #ccc;
            word-break: break-word;

            @media screen and (max-width: 767px){
                text-align: left;
            }
        }
        th {
            background-color: #f3f3f3;
        }
    }
}